.c3 svg {
  -webkit-tap-highlight-color: transparent;
  font: 10px Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif;
}

.c3 path, .c3 line {
  fill: none;
  stroke: #0028641f;
}

.c3 text {
  -webkit-user-select: none;
  user-select: none;
  font-size: px2rem(12px);
}

.c3-legend-item-tile, .c3-xgrid-focus, .c3-ygrid, .c3-event-rect, .c3-bars path {
  shape-rendering: crispedges;
}

.c3-chart-arc path {
  stroke: #fff;
}

.c3-chart-arc text {
  fill: #fff;
  font-size: 13px;
}

.c3-grid line {
  stroke: #f0f0f0;
}

.c3-grid text {
  fill: #aaa;
}

.c3-xgrid, .c3-ygrid {
  stroke: #e6e6e6;
  stroke-dasharray: 2 4;
}

.c3-text {
  font-size: 12px;
}

.c3-text.c3-empty {
  fill: gray;
  font-size: 2em;
}

.c3-line {
  stroke-width: 2px;
}

.c3-circle._expanded_ {
  stroke-width: 2px;
  stroke: #fff;
}

.c3-selected-circle {
  fill: #fff;
  stroke-width: 1.5px;
}

.c3-bar {
  stroke-width: 0;
}

.c3-bar._expanded_ {
  fill-opacity: 1;
  fill-opacity: .75;
}

.c3-target.c3-focused {
  opacity: 1;
}

.c3-target.c3-focused path.c3-line, .c3-target.c3-focused path.c3-step {
  stroke-width: 2px;
}

.c3-target.c3-defocused {
  opacity: .3 !important;
}

.c3-region {
  fill: #4682b4;
  fill-opacity: .1;
}

.c3-brush .extent {
  fill-opacity: .1;
}

.c3-legend-item text {
  fill: #545454;
  font-size: 14px;
}

.c3-legend-item-hidden {
  opacity: .15;
}

.c3-legend-background {
  fill: #0000;
  stroke: #d3d3d3;
  stroke-width: 0;
}

.c3-title {
  font: 14px sans-serif;
}

.c3-tooltip-container {
  z-index: 10;
}

.c3-tooltip {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  color: #fff;
  white-space: nowrap;
  background: #212529;
  border-radius: 3px;
  font-size: 11px;
  font-weight: 700;
  line-height: 1;
}

.c3-tooltip th {
  text-align: left;
  padding: 6px;
}

.c3-tooltip td {
  padding: 4px 6px;
  font-weight: 400;
}

.c3-tooltip td > span {
  width: 8px;
  height: 8px;
  vertical-align: baseline;
  border-radius: 50%;
  margin-right: 8px;
  display: inline-block;
}

.c3-tooltip td.value {
  text-align: right;
}

.c3-area {
  stroke-width: 0;
  opacity: .1;
}

.c3-target-filled .c3-area {
  opacity: 1 !important;
}

.c3-chart-arcs-title {
  dominant-baseline: middle;
  font-size: 1.3em;
}

.c3-chart-arcs .c3-chart-arcs-background {
  fill: #e0e0e0;
  stroke: none;
}

.c3-chart-arcs .c3-chart-arcs-gauge-unit {
  fill: #000;
  font-size: 16px;
}

.c3-chart-arcs .c3-chart-arcs-gauge-max, .c3-chart-arcs .c3-chart-arcs-gauge-min {
  fill: #777;
}

.c3-chart-arc .c3-gauge-value {
  fill: #000;
}

.c3-chart-arc.c3-target g path, .c3-chart-arc.c3-target.c3-focused g path {
  opacity: 1;
}

.c3-axis {
  fill: #9aa0ac;
}

/*# sourceMappingURL=index.f5468f1a.css.map */
